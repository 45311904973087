import firebase from 'firebase/app';
import 'firebase/auth';
//const firebase = require("firebase");
require("firebase/functions");
//const cors = require('cors');
//const axios = require('axios');
require('cors')({ origin: true });

//for firebase authservice
var firebaseConfig = {
    apiKey: "AIzaSyAJjm0P1DDzpYEs1chwJ-UnCOKyyZdyHDI",
    authDomain: "customerdashboard-76cef.firebaseapp.com",
    projectId: "customerdashboard-76cef",
    storageBucket: "customerdashboard-76cef.appspot.com",
    messagingSenderId: "23995230765",
    appId: "1:23995230765:web:aac1311307b2a8807526a4",
    measurementId: "G-4D1SB4H831"
  };

//for firebase functions
/*firebase.initializeApp({
  apiKey: "AIzaSyAJjm0P1DDzpYEs1chwJ-UnCOKyyZdyHDI",
  authDomain: "customerdashboard-76cef.firebaseapp.com",
  projectId: "customerdashboard-76cef",
  //databaseURL: 'https://### YOUR DATABASE NAME ###.firebaseio.com',
});*/

// Initialize Cloud Functions through Firebase
//var functions = firebase.functions();

firebase.initializeApp(firebaseConfig);

  export default {
    auth: firebase.auth(),
    login() {
      var userEmail = document.getElementById("email").value;
      var userPassword = document.getElementById("password").value;
      //window.alert("Email : " + userEmail);

      firebase.auth().signInWithEmailAndPassword(userEmail, userPassword).catch(function(error) {
        // Handle Errors here.
        //var errorCode = error.code;
        var errorMessage = error.message;

        window.alert("Error : " + errorMessage);

        // ...
      });
      //var userPass = document.getElementById("password_field").value;
      /*const provider = new firebase.auth.GoogleAuthProvider();
      firebase.auth().signInWithPopup(provider)
      .then(function(result) {
        console.log(result);
      })
      .catch(function(error){
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.email;
        const credential = error.credential;
        console.log(errorCode, errorMessage, email, credential);
      })*/
    },
    logout() {

      //var userId = "";
      /*try{
        firebase.auth().currentUser.getIdToken(true)
        .then(function (token) {
            // You got the user token
            userId = decodedToken.uid;
        })
        .catch(function (err) {
            console.error(err);
        });*/
        //var postbody = {"userId": "Trial"};
      /*  axios
        .get('https://us-central1-customerdashboard-76cef.cloudfunctions.net/getOrderStatus', {"userId":"Trial"}, {
                headers: {
                                'Content-Type' : 'application/json',
                                'Access-Control-Allow-Origin' : '*',
                                'Access-Control-Allow-Credentials': 'true'
                            }
          })
          .then(resp => {
            console.log(`statusCode: ${resp.statusCode}`)
            console.log('Response Received:')
            console.log(resp)
            console.log("\n\n Data:");
            console.log(resp.data);
          })
          .catch(error => {
            console.error(error)
          })

        var getOrderStatus = firebase.functions().httpsCallable('orderStatus');
        getOrderStatus({'userId': "Trial"})
          .then((result) => {
            // Read result of the Cloud Function.
            //var sanitizedMessage = result.data.text;
            console.log(result);
          });
      } catch (e) {
        console.log(e);
      }*/
      firebase.auth().signOut()
      .then(function() {})
      .catch(function(error) {
        console.log(error)});
    },
    resetPassword() {
      var userEmail = document.getElementById("email").value;
      firebase.auth().sendPasswordResetEmail(userEmail).then(
        () => {
          // success, show some message
          window.alert("Reset link will be sent to " + userEmail + " (Check junk box as well)");
        })
        .catch(function(error) {
          // handle errors
          window.alert("Check entered email id.   Error: " + error );
        }
      );
    },

    async getOrderData(userId) {
      console.log("Getting order data. For id: " + userId);
      try{
        var getOrderStatus = firebase.functions().httpsCallable('orderStatus');
        var result = await getOrderStatus({'userId': userId});
        console.log(result);
        return result;
        /*
          .then((result) => {
            // Read result of the Cloud Function.
            //var sanitizedMessage = result.data.text;
            console.log(result);
          });*/
      } catch (e) {
        console.log(e);
      }
    },

    async getDispatchData(userId) {
      console.log("Getting dispatch data. For id: " + userId);
      try{
        var getDispatchStatus = firebase.functions().httpsCallable('dispatchStatus');
        var result = await getDispatchStatus({'userId': userId});
        console.log(result);
        return result;
        /*
          .then((result) => {
            // Read result of the Cloud Function.
            //var sanitizedMessage = result.data.text;
            console.log(result);
          });*/
      } catch (e) {
        console.log(e);
      }
    }
}
