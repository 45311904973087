import Vue from 'vue'
import App from './App.vue'
//import './assets/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
//import AxiosPlugin from 'vue-axios-plugin'

Vue.config.productionTip = false

//AxiosPlugin.defaults.headers.get['Access-Control-Allow-Origin'] = '*'

new Vue({
  render: h => h(App),
}).$mount('#app')

//Vue.use(AxiosPlugin);

/*Vue.use(VueAxiosPlugin, {
  // request interceptor handler
  reqHandleFunc: config => config,
  reqErrorFunc: error => Promise.reject(error),
  // response interceptor handler
  resHandleFunc: response => response,
  resErrorFunc: error => Promise.reject(error)
})*/
