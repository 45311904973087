<template>
  <div class="hello">

  <h1 id="customerdashboardText" class="display-1">Customer Dashboard</h1>

  <div id="login_div" v-if="!authenticated" >
    <h1 class="display-1">Welcome!</h1>
    <p><input id="email" placeholder="Email / User"></p>
    <p><input type="password" id="password" placeholder="Password"></p>
    <p><button class="btn btn-primary" @click="login">Login to Account</button></p>
    <!-- Copyright -->
    <div id="loginFooter" class="text-center bg-light text-muted container-fluid">
      <p><button class="btn btn-link" id='resetButton' @click="passwordReset">Send Reset Password Link</button></p>
      <div style="background-color: rgba(0, 0, 0, 0.05);" class="text-center bg-light text-muted container-fluid">
        <a class="text-reset fw-bold" style="font-size : 17px" href="https://sprinpak.in/">Sprinpak Manufactuting LLP</a>
        <p style="font-size : 15px"> Use your registered email to login to the page. If you cannot remember your password, use the reset password link at the bottom of the page. </p>
        <p style="font-size : 15px"> Contact your CRM for any other details. </p>
        <p style="font-size : 11px"> Login service is provided by Google. Sprinpak Manufactuting LLP or any of it's associates cannot view your password, or other sensetive details.</p>
      </div>
    </div>
    <!-- Copyright -->
  </div>

  <div v-if="authenticated">
    <button class="btn btn-info" id="logoutButton" @click="logout">Logout</button>

    <!-- <h1>Hi {{ firstName }}!</h1>
    <h1>{{ msg }}</h1>
      {{orders}}
      <h1>{{ msg }}</h1>
      <p>
        For a guide and recipes on how to configure / customize this project,<br>
        check out the
        <a href="https://cli.vuejs.org" target="_blank" rel="noopener">vue-cli documentation</a>.
      </p> -->
      <vue-tabs type="pills" style="margin-top:-40px">
        <v-tab title="Orders">
              <!-- For Order Status -->
              <div id="ordersTable">
              <!--<div dir="ltr">-->
              <!--<div dir="ltr">-->
                  <table cellspacing="0" cellpadding="0" dir="ltr" style="table-layout:auto;font-size:10pt;font-family:Arial;width:100%;margin-left:0px;margin-top:20px">
                      <colgroup>
                      <col width="65">
                      <col width="56">
                      <col width="42">
                      <col width="257">
                      <col width="57">
                      <col width="93">
                      <col width="80">
                      <col width="139">
                      <col width="66">
                      <col width="134">
                      <col width="127">
                      </colgroup>
                      <tbody>
                          <tr style="height:12px">
                            <td colspan="11" style="border-width:1px;border-style:solid;border-color:rgb(0,0,0);overflow:hidden;padding:2px 3px;vertical-align:bottom;background-color:rgb(217,217,217);font-size:6pt;font-weight:bold">*Date of Dispatch is only an estimate and will depend on variuos external factors like raw material availibity etc. The dispacth date will be updated from time to time.</td>
                          </tr>
                          <tr style="height:21px">
                              <td style="border-width:1px;border-style:solid;border-color:rgb(0,0,0);overflow:hidden;padding:2px 3px;vertical-align:bottom;background-color:rgb(217,217,217);font-size:7pt;font-weight:bold">Booking Date</td>
                              <td style="border-top:1px solid rgb(0,0,0);border-right:1px solid rgb(0,0,0);border-bottom:1px solid rgb(0,0,0);overflow:hidden;padding:2px 3px;vertical-align:bottom;background-color:rgb(217,217,217);font-size:7pt;font-weight:bold">PO No.</td>
                              <td style="border-top:1px solid rgb(0,0,0);border-right:1px solid rgb(0,0,0);border-bottom:1px solid rgb(0,0,0);overflow:hidden;padding:2px 3px;vertical-align:bottom;background-color:rgb(217,217,217);font-size:7pt;font-weight:bold">Job No.</td>
                              <td style="border-top:1px solid rgb(0,0,0);border-right:1px solid rgb(0,0,0);border-bottom:1px solid rgb(0,0,0);overflow:hidden;padding:2px 3px;vertical-align:bottom;background-color:rgb(217,217,217);font-size:7pt;font-weight:bold">Artwork Name</td>
                              <td style="border-top:1px solid rgb(0,0,0);border-right:1px solid rgb(0,0,0);border-bottom:1px solid rgb(0,0,0);overflow:hidden;padding:2px 3px;vertical-align:bottom;background-color:rgb(217,217,217);font-size:7pt;font-weight:bold">Order Qty</td>
                              <td style="border-top:1px solid rgb(0,0,0);border-right:1px solid rgb(0,0,0);border-bottom:1px solid rgb(0,0,0);overflow:hidden;padding:2px 3px;vertical-align:bottom;background-color:rgb(217,217,217);font-size:7pt;font-weight:bold">Production Status</td>
                              <td style="border-top:1px solid rgb(0,0,0);border-right:1px solid rgb(0,0,0);border-bottom:1px solid rgb(0,0,0);overflow:hidden;padding:2px 3px;vertical-align:bottom;background-color:rgb(217,217,217);font-size:7pt;font-weight:bold">Dispatched Qty</td>
                              <td style="border-top:1px solid rgb(0,0,0);border-right:1px solid rgb(0,0,0);border-bottom:1px solid rgb(0,0,0);overflow:hidden;padding:2px 3px;vertical-align:bottom;background-color:rgb(217,217,217);font-size:7pt;font-weight:bold">Est. Date of Dispatch/Sailing</td>
                              <td style="border-top:1px solid rgb(0,0,0);border-right:1px solid rgb(0,0,0);border-bottom:1px solid rgb(0,0,0);overflow:hidden;padding:2px 3px;vertical-align:bottom;background-color:rgb(217,217,217);font-size:7pt;font-weight:bold">Basic Rate</td>
                              <td style="border-top:1px solid rgb(0,0,0);border-right:1px solid rgb(0,0,0);border-bottom:1px solid rgb(0,0,0);overflow:hidden;padding:2px 3px;vertical-align:bottom;background-color:rgb(217,217,217);font-size:7pt;font-weight:bold">Delivery Terms</td>
                              <td style="border-top:1px solid rgb(0,0,0);border-right:1px solid rgb(0,0,0);border-bottom:1px solid rgb(0,0,0);overflow:hidden;padding:2px 3px;vertical-align:bottom;background-color:rgb(217,217,217);font-size:7pt;font-weight:bold">Delivery Address</td>
                          </tr>
                          <tr style="height:21px" v-for="(order,i) in orders" :key="i" :bgcolor="order[12]">
                          <!-- <tr style="height:21px"> :bgcolor="order[12]"-->
                              <td style="border-right:1px solid rgb(0,0,0);border-bottom:1px solid rgb(0,0,0);border-left:1px solid rgb(0,0,0);overflow:hidden;padding:2px 3px;vertical-align:bottom;font-size:7pt;text-align:right">{{ order[1] }}</td>
                              <td style="border-right:1px solid rgb(0,0,0);border-bottom:1px solid rgb(0,0,0);overflow:hidden;padding:2px 3px;vertical-align:bottom;font-size:7pt;text-align:right">{{ order[2] }}</td>
                              <td style="border-right:1px solid rgb(0,0,0);border-bottom:1px solid rgb(0,0,0);overflow:hidden;padding:2px 3px;vertical-align:bottom;font-size:7pt;text-align:right">{{ order[3] }}</td>
                              <td style="border-right:1px solid rgb(0,0,0);border-bottom:1px solid rgb(0,0,0);overflow:hidden;padding:2px 3px;vertical-align:bottom;font-size:7pt">{{ order[4] }}</td>
                              <td style="border-right:1px solid rgb(0,0,0);border-bottom:1px solid rgb(0,0,0);overflow:hidden;padding:2px 3px;vertical-align:bottom;font-size:7pt;text-align:right">{{ order[5] }}</td>
                              <td style="border-right:1px solid rgb(0,0,0);border-bottom:1px solid rgb(0,0,0);overflow:hidden;padding:2px 3px;vertical-align:bottom;font-size:7pt;text-align:right">{{ order[6] }}</td>
                              <td style="border-right:1px solid rgb(0,0,0);border-bottom:1px solid rgb(0,0,0);overflow:hidden;padding:2px 3px;vertical-align:bottom;font-size:7pt;text-align:right">{{ order[7] }}</td>
                              <td style="border-right:1px solid rgb(0,0,0);border-bottom:1px solid rgb(0,0,0);overflow:hidden;padding:2px 3px;vertical-align:bottom;font-size:7pt;text-align:right">{{ order[8] }}</td>
                              <td style="border-right:1px solid rgb(0,0,0);border-bottom:1px solid rgb(0,0,0);overflow:hidden;padding:2px 3px;vertical-align:bottom;font-size:7pt;text-align:right">{{ order[9] }}</td>
                              <td style="border-right:1px solid rgb(0,0,0);border-bottom:1px solid rgb(0,0,0);overflow:hidden;padding:2px 3px;vertical-align:bottom;font-size:7pt">{{ order[10] }}</td>
                              <td style="border-right:1px solid rgb(0,0,0);border-bottom:1px solid rgb(0,0,0);overflow:hidden;padding:2px 3px;vertical-align:bottom;font-size:7pt">{{ order[11] }}</td>
                          <!-- </tr> -->
                          </tr>
                      </tbody>
                  </table>
                  <br>
                  <div v-if="user.loading" class="spinner-border text-info" role="status">
                    <span class="sr-only"></span>
                  </div>
              </div>

          <!--</div>-->
          <!--</div>-->
          <!-- For Order Status ENDS -->
        </v-tab>

        <v-tab title="Dispatches">
          <div id="ordersTable">
                <table cellspacing="0" cellpadding="0" dir="ltr" style="table-layout:auto;font-size:10pt;font-family:Arial;width:100%;margin-left:0px;margin-top:20px">
                    <colgroup>
                      <col width="65">
                      <col width="56">
                      <col width="207">
                      <col width="57">
                      <col width="60">
                      <col width="93">
                      <col width="80">
                      <col width="139">
                      <col width="139">
                      <col width="116">
                      <col width="116">
                    </colgroup>
                    <tbody>
                        <tr style="height:21px">
                            <td style="border-width:1px;border-style:solid;border-color:rgb(0,0,0);overflow:hidden;padding:2px 3px;vertical-align:bottom;background-color:rgb(217,217,217);font-size:7pt;font-weight:bold">PO No.</td>
                            <td style="border-top:1px solid rgb(0,0,0);border-right:1px solid rgb(0,0,0);border-bottom:1px solid rgb(0,0,0);overflow:hidden;padding:2px 3px;vertical-align:bottom;background-color:rgb(217,217,217);font-size:7pt;font-weight:bold">Job No.</td>
                            <td style="border-top:1px solid rgb(0,0,0);border-right:1px solid rgb(0,0,0);border-bottom:1px solid rgb(0,0,0);overflow:hidden;padding:2px 3px;vertical-align:bottom;background-color:rgb(217,217,217);font-size:7pt;font-weight:bold">Artwork Name</td>
                            <td style="border-top:1px solid rgb(0,0,0);border-right:1px solid rgb(0,0,0);border-bottom:1px solid rgb(0,0,0);overflow:hidden;padding:2px 3px;vertical-align:bottom;background-color:rgb(217,217,217);font-size:7pt;font-weight:bold">Invoice No.</td>
                            <td style="border-top:1px solid rgb(0,0,0);border-right:1px solid rgb(0,0,0);border-bottom:1px solid rgb(0,0,0);overflow:hidden;padding:2px 3px;vertical-align:bottom;background-color:rgb(217,217,217);font-size:7pt;font-weight:bold">Packing List</td>
                            <td style="border-top:1px solid rgb(0,0,0);border-right:1px solid rgb(0,0,0);border-bottom:1px solid rgb(0,0,0);overflow:hidden;padding:2px 3px;vertical-align:bottom;background-color:rgb(217,217,217);font-size:7pt;font-weight:bold">Dispatch Date</td>
                            <td style="border-top:1px solid rgb(0,0,0);border-right:1px solid rgb(0,0,0);border-bottom:1px solid rgb(0,0,0);overflow:hidden;padding:2px 3px;vertical-align:bottom;background-color:rgb(217,217,217);font-size:7pt;font-weight:bold">Dispatched Qty</td>
                            <td style="border-top:1px solid rgb(0,0,0);border-right:1px solid rgb(0,0,0);border-bottom:1px solid rgb(0,0,0);overflow:hidden;padding:2px 3px;vertical-align:bottom;background-color:rgb(217,217,217);font-size:7pt;font-weight:bold">Status</td>
                            <td style="border-top:1px solid rgb(0,0,0);border-right:1px solid rgb(0,0,0);border-bottom:1px solid rgb(0,0,0);overflow:hidden;padding:2px 3px;vertical-align:bottom;background-color:rgb(217,217,217);font-size:7pt;font-weight:bold">Transporter</td>
                            <td style="border-top:1px solid rgb(0,0,0);border-right:1px solid rgb(0,0,0);border-bottom:1px solid rgb(0,0,0);overflow:hidden;padding:2px 3px;vertical-align:bottom;background-color:rgb(217,217,217);font-size:7pt;font-weight:bold">Transportation Details</td>
                            <td style="border-top:1px solid rgb(0,0,0);border-right:1px solid rgb(0,0,0);border-bottom:1px solid rgb(0,0,0);overflow:hidden;padding:2px 3px;vertical-align:bottom;background-color:rgb(217,217,217);font-size:7pt;font-weight:bold">Transportation Docs</td>
                            <td style="border-top:1px solid rgb(0,0,0);border-right:1px solid rgb(0,0,0);border-bottom:1px solid rgb(0,0,0);overflow:hidden;padding:2px 3px;vertical-align:bottom;background-color:rgb(217,217,217);font-size:7pt;font-weight:bold">Transportation Docs</td>
                        </tr>
                        <tr style="height:21px" v-for="(dispatch,i) in dispatches" :key="i">
                            <td style="border-right:1px solid rgb(0,0,0);border-bottom:1px solid rgb(0,0,0);border-left:1px solid rgb(0,0,0);overflow:hidden;padding:2px 3px;vertical-align:bottom;font-size:7pt;text-align:right">{{ dispatch[1] }}</td>
                            <td style="border-right:1px solid rgb(0,0,0);border-bottom:1px solid rgb(0,0,0);overflow:hidden;padding:2px 3px;vertical-align:bottom;font-size:7pt;text-align:right">{{ dispatch[2] }}</td>
                            <td style="border-right:1px solid rgb(0,0,0);border-bottom:1px solid rgb(0,0,0);overflow:hidden;padding:2px 3px;vertical-align:bottom;font-size:7pt">{{ dispatch[3] }}</td>
                            <td style="border-right:1px solid rgb(0,0,0);border-bottom:1px solid rgb(0,0,0);overflow:hidden;padding:2px 3px;vertical-align:bottom;font-size:7pt;text-align:right"><a :href= "dispatch[5]" target="_blank" >{{ dispatch[4] }}</a></td>
                            <td style="border-right:1px solid rgb(0,0,0);border-bottom:1px solid rgb(0,0,0);overflow:hidden;padding:2px 3px;vertical-align:bottom;font-size:7pt"><a :href= "dispatch[6]" target="_blank" >Download</a></td>
                            <td style="border-right:1px solid rgb(0,0,0);border-bottom:1px solid rgb(0,0,0);overflow:hidden;padding:2px 3px;vertical-align:bottom;font-size:7pt;text-align:right">{{ dispatch[7] }}</td>
                            <td style="border-right:1px solid rgb(0,0,0);border-bottom:1px solid rgb(0,0,0);overflow:hidden;padding:2px 3px;vertical-align:bottom;font-size:7pt;text-align:right">{{ dispatch[8] }}</td>
                            <td style="border-right:1px solid rgb(0,0,0);border-bottom:1px solid rgb(0,0,0);overflow:hidden;padding:2px 3px;vertical-align:bottom;font-size:7pt;text-align:right">{{ dispatch[9] }}</td>
                            <td style="border-right:1px solid rgb(0,0,0);border-bottom:1px solid rgb(0,0,0);overflow:hidden;padding:2px 3px;vertical-align:bottom;font-size:7pt;text-align:right">{{ dispatch[10] }}</td>
                            <td style="border-right:1px solid rgb(0,0,0);border-bottom:1px solid rgb(0,0,0);overflow:hidden;padding:2px 3px;vertical-align:bottom;font-size:7pt;text-align:right">{{ dispatch[11] }}</td>
                            <td style="border-right:1px solid rgb(0,0,0);border-bottom:1px solid rgb(0,0,0);overflow:hidden;padding:2px 3px;vertical-align:bottom;font-size:7pt;text-align:right"><a :href= "dispatch[13]" target="_blank" >{{ dispatch[12] }}</a></td>
                            <td style="border-right:1px solid rgb(0,0,0);border-bottom:1px solid rgb(0,0,0);overflow:hidden;padding:2px 3px;vertical-align:bottom;font-size:7pt;text-align:right"><a :href= "dispatch[15]" target="_blank" >{{ dispatch[14] }}</a></td>
                        </tr>
                    </tbody>
                </table>
                <br>
                <div v-if="user.loading" class="spinner-border text-info" role="status">
                  <span class="sr-only"></span>
                </div>
              </div>
        </v-tab>

        <!--<v-tab title="Third tab">
          Third tab content
        </v-tab>-->
      </vue-tabs>

      <p></p>
      <p></p>
      <p></p>
      <p></p>
      <p></p>

      <!-- Footer -->
      <footer id="footer" class="text-center text-lg-start bg-light container-fluid">
        <!-- Copyright -->
        <div class="text-center p-2" style="font-size : 12px background-color: rgba(0, 0, 0, 0.05);">
          <a class="text-reset fw-bold" style="font-size : 12px" href="https://sprinpak.in/">Sprinpak Manufactuting LLP</a>
          <p style="font-size : 11px">
             This page including any attachment contains confidential , proprietary or legally privileged information. It should not be used by the person who is not the original intended recipient. If you have erroneously received the details, you are notified that you are strictly prohibited from using, copying, altering or disclosing the content of this message.
          </p>
        </div>
        <!-- Copyright -->
      </footer>
      <!-- Footer -->


    </div>
  </div>
</template>

<script>
import Firebase from '../firebase.js';
import {VueTabs, VTab} from 'vue-nav-tabs'
import 'vue-nav-tabs/themes/vue-tabs.css'
//import { mdbFooter, mdbContainer, mdbRow, mdbCol } from 'mdbvue';

export default {
  name: 'HelloWorld',
  activeItem: 'home',
  props: {
    msg: String
  },
  components: {
    VueTabs,
    VTab,
//    mdbFooter,//for footer
//    mdbContainer,
//    mdbRow,
//    mdbCol
  },
  data () {
      return {
        user: {
          loggedIn: false,
          data: {},
          orders: [],
          dispatches: [],
          loading: true
        }
      }
    },
    computed: {
        authenticated(){
          return this.user.loggedIn
        },
        firstName(){
          if (this.user.data.displayName) {
            return this.user.data.displayName.split(' ')[0]
          }
          return null
        },
        orders() {
          return this.user.orders
          /*if (this.user.data.orders) {
            return this.user.data.orders
          }
          return "No orders"*/
        },
        dispatches() {
          return this.user.dispatches
        }
    },
    methods: {
      isActive (menuItem) {
      return this.activeItem === menuItem
      },
      setActive (menuItem) {
        console.log("selected tab: " + menuItem);
        this.activeItem = menuItem
      },
      login() {
        Firebase.login();
      },
      logout() {
        Firebase.logout()
      },
      passwordReset() {
          Firebase.resetPassword()
      },
    },
    mounted: function() {
      Firebase.auth.onAuthStateChanged( user => {
        if (user) {
          this.user.loggedIn = true;
          this.user.data = user;
          this.user.loading = true;
          Firebase.getOrderData(user.uid).then((result) => {
            this.user.orders = result.data.Orders;
            console.log(this.user.orders);
            this.user.loading = false;
            console.log(this.user.loading);
          })
          Firebase.getDispatchData(user.uid).then((result) => {
            this.user.dispatches = result.data.Dispatches;
            console.log(this.user.dispatches);
            this.user.loading = false;
          })
          //user.loading = false;
          /*this.user.orders = Firebase.getUserData(user.uid)["orders"];
          console.log(this.user.orders);*/



        }
        else {
          this.user.loggedIn = false;
          this.user.data = {};
        }
      })
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
#logoutButton {
  position: absolute;
  right: 10px;
  top: 55px;
  font-size: 12px;
}
#resetButton {
  position: absolute;
  right: 10px;
  top: -40px;
  width: 200px;
  font-size: 15px;
}
#ordersTable {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  overflow: auto;
}
#customerdashboardText {
  position: absolute;
  font-size: min(4vw,30px);
  right: 10px;
  top: 20px;
}
#footer {
  position: absolute;
  margin: 0px;
}
#loginFooter {
  position: fixed;
  overflow-anchor: initial;
  bottom: 0px;
  margin: 0px;
}

</style>
